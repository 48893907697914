import { Dialog } from "@headlessui/react";
import { Modal } from "./Modal";
import { Button } from "../Button";

export default function DeleteModal({
  open,
  setOpen,
  onDelete,
}: {
  open: boolean;
  setOpen: any;
  onDelete: any;
}) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <Dialog.Panel className="w-fit transform overflow-hidden rounded-2xl p-[23px_12px] pt-3 font-roboto text-left align-middle shadow-xl transition-all relative bg-white">
        <div className="font-bold text-2xl mb-5">Delete</div>
        <div className="">
          <div>Are you sure want to delete these accounts?</div>
        </div>
        <div className="flex mt-5">
          <Button
            onClick={() => {
              onDelete();
              setOpen(false);
            }}
            className={"flex-1 mr-2"}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            className={"flex-1 mr-2 bg-white !text-black border !border-black"}
          >
            No
          </Button>
        </div>

        <div
          className="absolute right-2.5 top-2.5 w-8 h-8 z-10 font-black text-xs bg-[#B48FC5] text-[#2E2E2E] rounded box-shadow cursor-pointer flex justify-center items-center"
          onClick={() => setOpen(false)}
        >
          X
        </div>
      </Dialog.Panel>
    </Modal>
  );
}
