export function Button({
  type,
  children,
  className,
  onClick,
}: {
  type?: any;
  children: any;
  className?: any;
  onClick?: any;
}) {
  const baseClassName =
    "bg-[#003cff] rounded text-sm p-[10px_16px] text-white font-semibold uppercase";
  return (
    <button className={`${baseClassName} ${className}`} onClick={onClick}>
      {children}
    </button>
  );
}
