// @ts-ignore
import TronWeb from "tronweb";
import { API_URL } from "./config/constants";
export const tronWeb: any = new TronWeb({
  fullHost: API_URL,
  solidityNode: API_URL,
  eventServer: API_URL,
  privateKey:
    "251f927dc8138d54120bd644ecdb1a777975444e7faf97c0a1c6835711025f12",
});
(window as any).tronWeb1 = tronWeb;
