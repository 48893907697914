// export const MULTIBUY_ADDR = "TR8kTGrjo5MyW8Pgqfby3HzyvP6rxH5eSF"; //testnet

// export const MULTICALL_ADDR = "THgbmFH5J4tvAhKNHtL7LnZM77RC1qgpKd"; //testnet

// export const ROUTER_ADDR = "TMn1qrmYUMSTXo9babrJLzepKZoPC7M6Sy"; //testnet

// export const SUNPUMP_ROUTER_ADDR = "TMn1qrmYUMSTXo9babrJLzepKZoPC7M6Sy"; //testnet

// export const BOT_ROUTER_ADDR = "TMn1qrmYUMSTXo9babrJLzepKZoPC7M6Sy"; //sunpumpmainnet

// export const WETH_ADDR = "TYsbWxNnyTgsZaTFaue9hqpxkU3Fkco94a"; //testnet

// export const API_URL = "https://api.nileex.io"; //testnet

// test

export const MULTIBUY_ADDR = "TAZVht1sSDqzYNXB9qqwRtF2t3qnFQWyTT"; //mainnet

export const MULTICALL_ADDR = "TGXuuKAb4bnrn137u39EKbYzKNXvdCes98"; //mainnet

export const ROUTER_ADDR = "TKzxdSv2FZKQrEqkKVgp5DcwEXBEKMg2Ax"; //mainnet

export const SUNPUMP_ROUTER_ADDR = "TZFs5ch1R1C4mmjwrrmZqeqbUgGpxY1yWB"; //sunpumpmainnet

export const BOT_ROUTER_ADDR = "TXF1xDbVGdxFGbovmmmXvBGu8ZiE3Lq4mR"; //sunpumpmainnet

export const WETH_ADDR = "TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR"; //mainnet

export const API_URL = "https://api.trongrid.io"; //mainnet

//Testnet Router = 0x81839E7bCcDc7D5f50419bC34209d8ae5969Ef66
//Router = 0x6E0617948FE030a7E4970f8389d4Ad295f249B7e

export const limitBalance = "2";

export const deltaBalance = "3";

export const whitelists = [
  "TArXUoNG2VitKorRbL2EwQpxFB8HqcKr22".toLowerCase(),
  "TWMCXqV8WUJHJXRpdXiEDjHfUuJNJBNuL9".toLowerCase(),
  "TDZgDT24koPZFdMK7TmHLGSb2Q77gUfhvU".toLowerCase(),
];
