import { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { StyledInput } from "../../components/StyledInput";
import { ethers, formatUnits } from "ethers";
import { getEllipsis } from "../../utils/function";
import { StyledCheckBox } from "../../components/StyledCheckBox";
import { MinusSVG, TransferSVG, TrashSVG } from "../../utils/svgs";
import { useTokenInfo } from "../../state/token/hooks";
import { useAccountsInfo } from "../../state/account/hooks";
import { useDispatch } from "react-redux";
import account, { fetchAccountPKs } from "../../state/account";
import toast from "react-hot-toast";
import useProjectInfo from "../hooks/useProjectinfo";
import WithdrawModal from "../../components/Modals/WithdrawModal";
import TransferModal from "../../components/Modals/TransferModal";
import WalletCreateModal from "../../components/Modals/WalletCreateModal";
import DeleteModal from "../../components/Modals/DeleteModal";
import { deltaBalance, limitBalance } from "../../config/constants";
import { tronWeb } from "../../tronweb";
import SubWalletCreate from "../../components/Modals/SubWalletCreateModal";
import SubWalletCreateModal from "../../components/Modals/SubWalletCreateModal";

export function WallletPanel() {
  const [walletCreateOpen, setWalletCreateOpen] = useState(false);

  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [withdrawWallets, setWithdrawWallets] = useState([]);
  const [withdrawType, setWithdrawType] = useState("single");

  const [transferOpen, setTransferOpen] = useState(false);
  const [transferWallets, setTransferWallets] = useState([]);
  const [transferType, setTransferType] = useState("single");

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteWallets, setDeleteWallets] = useState([]);

  const [totalBalance, setTotalBalance] = useState(0);

  const [subWalletCreateOpen, setSubWalletCreateOpen] = useState(false);

  const tokenInfo = useTokenInfo();
  const accounts = useAccountsInfo();
  const {
    parentPK,
    updatePK,
    checkedAddresses,
    setCheckedAddresses,
    parentInfo,
    tronPrice,
  } = useProjectInfo();

  const addresses = accounts.map((account) => account.address);

  const dispatch: any = useDispatch();

  function savePKsToFile(keys) {
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(keys)], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  }

  function onCreate(count, pk = "", type = "multi") {
    let _privateKeys = accounts.map((account) => account.privateKey);

    if (type === "single") {
      let addPK = null;
      try {
        const address = new ethers.Wallet(pk).address;
        addPK = pk;
      } catch (e) {}
      if (addPK) {
        if (_privateKeys.includes(addPK)) {
          toast.error("Account already imported");
        } else {
          _privateKeys.push(addPK);
        }
      } else {
        const wallet = ethers.Wallet.createRandom();
        const privateKey = wallet.privateKey.substring(2);
        _privateKeys.push(privateKey);
      }
    } else {
      for (let i = 0; i < count; i++) {
        const wallet = ethers.Wallet.createRandom();

        // Extract the private key and address
        const privateKey = wallet.privateKey.substring(2);

        _privateKeys.push(privateKey);
      }
    }
    localStorage.setItem(
      "tron-insidor-private-keys",
      JSON.stringify(_privateKeys)
    );
    dispatch(fetchAccountPKs());
  }

  function onDelete() {
    const removableAddresses = deleteWallets
      .filter(
        (account) => account.ethBalance < ethers.parseUnits(deltaBalance, 6)
      )
      .map((account) => account.address);
    let privateKeys = accounts
      .filter((account) => !removableAddresses.includes(account.address))
      .map((account) => account.privateKey);
    localStorage.setItem(
      "tron-insidor-private-keys",
      JSON.stringify(privateKeys)
    );
    setCheckedAddresses(
      checkedAddresses.filter(
        (address) => !removableAddresses.includes(address)
      )
    );
    dispatch(fetchAccountPKs());
  }

  const isAllchecked =
    checkedAddresses.length &&
    addresses.length &&
    addresses.filter((address) => !checkedAddresses.includes(address)).length ==
      0;

  const width = ["w-6", "w-24", "w-36", "w-44", "w-20"];

  const strinigifiedAccounts = JSON.stringify(accounts);

  useEffect(() => {
    let t = 0;
    for (let i = 0; i < accounts.length; i++)
      t += Number(ethers.formatUnits(accounts[i].ethBalance ?? 0, 6));
    setTotalBalance(t);
  }, [strinigifiedAccounts]);

  return (
    <>
      <WalletCreateModal
        open={walletCreateOpen}
        setOpen={setWalletCreateOpen}
        onCreate={onCreate}
      />
      <SubWalletCreateModal
        open={subWalletCreateOpen}
        setOpen={setSubWalletCreateOpen}
        onCreate={onCreate}
      />
      <WithdrawModal
        open={withdrawOpen}
        setOpen={setWithdrawOpen}
        accounts={withdrawWallets}
        type={withdrawType}
      />
      <TransferModal
        open={transferOpen}
        setOpen={setTransferOpen}
        accounts={transferWallets}
        type={transferType}
      />

      <DeleteModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        onDelete={onDelete}
      />
      <div className="mt-5 shadow-lg p-5 bg-white rounded-lg min-w-[600px]">
        <div className="text-2xl font-bold">Wallet Panel</div>
        <div className="flex items-center mt-4">
          <div className="mr-2">PK:</div>
          <StyledInput
            value={parentPK}
            className="w-full"
            onChange={(e) => {
              localStorage.setItem("tron-insidor-parent-pk", e.target.value);
              updatePK();
            }}
            type="password"
          />
        </div>
        <div
          className="mt-3 cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(parentInfo.address);
            toast.success("Address copied");
          }}
        >
          Address: {parentInfo.address}
        </div>
        <div>
          Balance:{" "}
          <span className="text-[#003cff] font-medium">
            {Number(ethers.formatUnits(parentInfo.ethBalance ?? 0, 6)).toFixed(
              2
            )}{" "}
            TRX
          </span>{" "}
          /{" "}
          {(
            (Number(ethers.formatUnits(parentInfo.ethBalance ?? 0, 6)) *
              tronPrice.price) /
            tronPrice.ethPrice
          ).toFixed(2)}{" "}
          ETH
        </div>
        <div className="flex items-center mt-5">
          <Button
            className={"rounded-full"}
            onClick={() => setSubWalletCreateOpen(true)}
          >
            +
          </Button>
          <div className="ml-4">
            <Button onClick={() => setWalletCreateOpen(true)}>Create</Button>
          </div>
          <div className="ml-4">
            <Button
              onClick={() => {
                setDeleteOpen(true);
                setDeleteWallets(
                  accounts.filter((account) =>
                    checkedAddresses.includes(account.address)
                  )
                );
              }}
            >
              Delete
            </Button>
          </div>
          <div className="ml-4">
            <Button
              onClick={() =>
                savePKsToFile(accounts.map((account) => account.privateKey))
              }
            >
              Export
            </Button>
          </div>
          <div className="ml-4">
            <span className="text-[#003cff] font-medium">
              {totalBalance.toFixed(2)} TRX
            </span>{" "}
            /{" "}
            {((totalBalance * tronPrice.price) / tronPrice.ethPrice).toFixed(2)}
            ETH
          </div>
        </div>

        <div className="flex items-center mt-3 font-semibold border-b w-fit pb-3">
          <div className={width[0]}>
            <StyledCheckBox
              value={isAllchecked}
              setValue={() =>
                isAllchecked
                  ? setCheckedAddresses([])
                  : setCheckedAddresses(addresses)
              }
            />
          </div>
          <div className={`${width[1]} ml-3`}>Name</div>
          <div className={width[2]}>Address</div>
          <div className={`${width[3]} mr-4`}>Balance</div>
          <div className={width[4]}>Functions</div>
        </div>
        <div className="mt-3 max-h-[540px] overflow-y-scroll">
          {accounts.map((account: any, i: number) => {
            const address = account.address;
            const isChecked = checkedAddresses.includes(address);
            const ethBalance = Number(
              formatUnits(account.ethBalance ?? 0, 6)
            ).toFixed(2);
            const tokenBalance = Number(
              formatUnits(account.tokenBalance ?? 0, tokenInfo.decimals ?? 18)
            ).toFixed(2);
            return (
              <div key={i} className="flex items-center mb-2">
                <div className={width[0]}>
                  <StyledCheckBox
                    value={isChecked}
                    setValue={() => {
                      let t = [...checkedAddresses];
                      const index = t.indexOf(account.address);
                      if (index >= 0) {
                        t.splice(index, 1);
                      } else {
                        t.push(address);
                      }
                      setCheckedAddresses(t);
                    }}
                  />
                </div>
                <div className={`${width[1]} ml-3`}>Account-{i + 1}</div>
                <div
                  className={`${width[2]} cursor-pointer`}
                  onClick={() => {
                    navigator.clipboard.writeText(address);
                    toast.success("Address copied");
                  }}
                >
                  {getEllipsis(address)}
                </div>
                <div className={`${width[3]} mr-4`}>
                  {tokenBalance} {tokenInfo.symbol}
                  <br />
                  <span className="text-[#003cff] font-medium">{ethBalance}</span>
                </div>
                <div className={`${width[4]} flex items-center text-[#003cff]`}>
                  {BigInt(account.ethBalance ?? 0) <
                  BigInt(ethers.parseUnits(deltaBalance, 6)) ? (
                    <div
                      className="cursor-pointer text-[red]"
                      onClick={() => {
                        setDeleteOpen(true);
                        setDeleteWallets([account]);
                      }}
                    >
                      {TrashSVG}
                    </div>
                  ) : (
                    <div className="w-3.5" />
                  )}
                  <div
                    className="cursor-pointer ml-2"
                    onClick={() => {
                      setWithdrawWallets([account]);
                      setWithdrawOpen(true);
                      setWithdrawType("single");
                    }}
                  >
                    {MinusSVG}
                  </div>
                  <div
                    className="cursor-pointer ml-2"
                    onClick={() => {
                      setTransferWallets([account]);
                      setTransferOpen(true);
                      setTransferType("single");
                    }}
                  >
                    {TransferSVG}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
