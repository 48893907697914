/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from "@reduxjs/toolkit";
import {
  getAccountETHBalances,
  getAccountTokenBalances,
} from "./fetchAccountInfo";
import { ethers } from "ethers";
import { tronWeb } from "../../tronweb";

const initialState = {
  accounts: [],
};

export const fetchAccountPKs = () => async (dispatch: any) => {
  let _privateKeys: any = localStorage.getItem("tron-insidor-private-keys");
  if (!_privateKeys) _privateKeys = [];
  else _privateKeys = JSON.parse(_privateKeys);
  dispatch(setAccountPKs(_privateKeys));
};

export const fetchAccountETHBalances = (accounts) => async (dispatch: any) => {
  const balances = await getAccountETHBalances(accounts);
  dispatch(setETHBalances(balances));
};

export const fetchAccountTokenBalances =
  (token, accounts) => async (dispatch: any) => {
    const balances = await getAccountTokenBalances(token, accounts);
    dispatch(setTokenBalances(balances));
  };

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAccountPKs: (state: any, action) => {
      const privateKeys = action.payload;
      let temp = [];
      for (let i = 0; i < privateKeys.length; i++) {
        const exist = state.accounts.find(
          (account) => account.privateKey === privateKeys[i]
        );
        if (!exist) {
          const wallet = new ethers.Wallet(privateKeys[i]);
          temp.push({
            address: tronWeb.address.fromHex(wallet.address),
            privateKey: privateKeys[i],
          });
        } else temp.push(exist);
      }
      state.accounts = temp;
    },
    setETHBalances: (state: any, action) => {
      const balances = action.payload;
      for (let i = 0; i < balances.length; i++) {
        for (let j = 0; j < state.accounts.length; j++) {
          if (state.accounts[j].address === balances[i].address)
            state.accounts[j].ethBalance = balances[i].balance;
        }
      }
    },
    setTokenBalances: (state: any, action) => {
      const balances = action.payload;
      for (let i = 0; i < balances.length; i++) {
        for (let j = 0; j < state.accounts.length; j++) {
          if (state.accounts[j].address === balances[i].address) {
            state.accounts[j].tokenBalance = balances[i].balance;
            state.accounts[j].allowance = balances[i].allowance;
            state.accounts[j].sunpumpAllowance = balances[i].sunpumpAllowance;
            state.accounts[j].botAllowance = balances[i].botAllowance;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {},
});

export const { setAccountPKs, setETHBalances, setTokenBalances } =
  analyticsSlice.actions;
export default analyticsSlice.reducer;
