import { Dialog } from "@headlessui/react";
import { Modal } from "./Modal";
import { ethers } from "ethers";
import { getEllipsis } from "../../utils/function";
import { StyledInput } from "../StyledInput";
import { useEffect, useState } from "react";
import { Button } from "../Button";
import { tronWeb } from "../../tronweb";
import MultiBuyABI from "../../config/abis/MultiBuy.json";
import { API_URL, MULTIBUY_ADDR } from "../../config/constants";
import TronWeb from "tronweb";
import useProjectInfo from "../../pages/hooks/useProjectinfo";
import toast from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { fetchAccountETHBalances } from "../../state/account";
import { useAccountsInfo } from "../../state/account/hooks";

export default function DepositModal({
  open,
  setOpen,
  addresses,
}: {
  open: boolean;
  setOpen: any;
  addresses: any;
}) {
  const [values, setValues] = useState([]);
  const { parentPK } = useProjectInfo();
  const [pending, setPending] = useState(false);

  const dispatch: any = useDispatch();
  const { getBalance } = useProjectInfo();
  const accounts = useAccountsInfo();

  async function onDeposit() {
    setPending(true);
    try {
      const tronWeb = new TronWeb({
        fullHost: API_URL,
        solidityNode: API_URL,
        eventServer: API_URL,
        privateKey: parentPK,
      });
      let tAmount = 0;
      const addrs = addresses.map((address) => {
        return tronWeb.address.fromHex(address);
      });

      const amounts = addresses.map((key, i) => {
        tAmount += Number(values[i] ?? 0);
        return ethers.parseUnits(Number(values[i] ?? 0).toString(), 6);
      });
      const MultiBuyContract = await tronWeb.contract(
        MultiBuyABI,
        MULTIBUY_ADDR
      );

      const txID = await MultiBuyContract.MultiSendETH(addrs, amounts).send({
        callValue: ethers.parseUnits(tAmount.toString(), 6),
        feeLimit: 15_000_000_000,
      });

      let result = await tronWeb.trx.getTransaction(txID);

      console.log(result);
      if (result?.ret?.[0]?.contractRet === "SUCCESS")
        toast.success("Deposited successfully");
      else toast.error(result?.ret?.[0]?.contractRet ?? "ERROR");
      dispatch(fetchAccountETHBalances(addresses));
      getBalance();
    } catch (e) {
      console.log(e);
      toast.success("Error");
    }
    setPending(false);
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <Dialog.Panel className="w-fit transform overflow-hidden rounded-2xl p-[23px_12px] pt-3 font-roboto text-left align-middle shadow-xl transition-all relative bg-white">
        <div className="font-bold text-2xl mb-5">Deposit</div>
        <div className="overflow-y-scroll max-h-[500px] pr-5">
          {addresses.map((address, i) => {
            const selectedAccount = accounts.find(
              (account) => account.address === address
            );
            return (
              <div key={i} className="mb-1">
                <div className="flex justify-between min-w-[300px]">
                  <div>{getEllipsis(tronWeb.address.fromHex(address))}</div>
                  <div className="text-[#003cff]">
                    {Number(
                      ethers.formatUnits(selectedAccount.ethBalance, 6)
                    ).toFixed(3)}
                  </div>
                </div>
                <StyledInput
                  value={values[i] ?? 0}
                  onChange={(e) => {
                    let t = [...values];
                    t[i] = e.target.value;
                    setValues(t);
                  }}
                  className="w-full"
                />
              </div>
            );
          })}
        </div>
        <div className="mt-4">
          {pending ? (
            <Oval visible={true} height="40" width="40" color="#4fa94d" />
          ) : (
            <Button onClick={() => onDeposit()}>Deposit</Button>
          )}
        </div>
        <div
          className="absolute right-2.5 top-2.5 w-8 h-8 z-10 font-black text-xs bg-[#B48FC5] text-[#2E2E2E] rounded box-shadow cursor-pointer flex justify-center items-center"
          onClick={() => setOpen(false)}
        >
          X
        </div>
      </Dialog.Panel>
    </Modal>
  );
}
