import { Dialog } from "@headlessui/react";
import { Modal } from "./Modal";
import { ethers } from "ethers";
import { StyledInput } from "../StyledInput";
import { useEffect, useState } from "react";
import { Button } from "../Button";
import MultiBuyABI from "../../config/abis/MultiBuy.json";
import { API_URL, deltaBalance, limitBalance, MULTIBUY_ADDR } from "../../config/constants";
import TronWeb from "tronweb";
import toast from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import account, { fetchAccountETHBalances } from "../../state/account";
import useProjectInfo from "../../pages/hooks/useProjectinfo";

export default function WithdrawModal({
  open,
  setOpen,
  accounts,
  type,
}: {
  open: boolean;
  setOpen: any;
  accounts: any;
  type: string;
}) {
  const [pending, setPending] = useState(false);
  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);

  const { getBalance } = useProjectInfo();

  const dispatch: any = useDispatch();

  async function onWithdraw() {
    setPending(true);
    try {
      await Promise.all(
        accounts
          .filter(
            (account) =>
              account.ethBalance >= ethers.parseUnits(deltaBalance, 6)
          )
          .map(async (account) => {
            const tronWeb = new TronWeb({
              fullHost: API_URL,
              solidityNode: API_URL,
              eventServer: API_URL,
              privateKey: account.privateKey,
            });

            console.log(
              (type === "all"
                ? BigInt(account.ethBalance) -
                  BigInt(ethers.parseUnits(limitBalance, 6))
                : ethers.parseUnits(Number(withdrawAmount ?? 0).toString(), 6)
              ).toString()
            );

            const transaction = await tronWeb.transactionBuilder.sendTrx(
              withdrawAddress,
              type === "all"
                ? BigInt(account.ethBalance) -
                    BigInt(ethers.parseUnits(limitBalance, 6))
                : ethers.parseUnits(Number(withdrawAmount ?? 0).toString(), 6),
              account.address
            );
            const signedTransaction = await tronWeb.trx.sign(
              transaction,
              account.privateKey
            );
            const receipt = await tronWeb.trx.sendRawTransaction(
              signedTransaction
            );
          })
      );

      toast.success("Withdrawed successfully");
      dispatch(
        fetchAccountETHBalances([
          ...accounts.map((account) => account.address),
          withdrawAddress,
        ])
      );
      getBalance();
    } catch (e) {
      console.log(e);
      toast.success("Error");
    }
    setPending(false);
  }

  const strinigfiedAccounts = JSON.stringify(accounts);

  useEffect(() => {
    let t = 0;
    for (let i = 0; i < accounts.length; i++) {
      t += Math.max(
        Number(ethers.formatUnits(accounts[i].ethBalance, 6)) -
          Number(limitBalance),
        0
      );
    }
    setTotalAmount(t);
  }, [strinigfiedAccounts]);

  useEffect(() => {
    setWithdrawAmount(totalAmount.toString());
  }, [totalAmount]);
  return (
    <Modal open={open} setOpen={setOpen}>
      <Dialog.Panel className="w-fit transform overflow-hidden rounded-2xl p-[23px_12px] pt-3 font-roboto text-left align-middle shadow-xl transition-all relative bg-white">
        <div className="font-bold text-2xl mb-5">Withdraw</div>
        <div className="text-right">Available: {totalAmount.toFixed(2)}</div>
        <div className="">
          <div>Withdraw Address</div>
          <StyledInput
            value={withdrawAddress}
            onChange={(e) => {
              setWithdrawAddress(e.target.value);
            }}
            className="w-[400px]"
          />
        </div>
        {type === "single" ? (
          <div className="mt-4">
            <div>Withdraw Amount</div>
            <StyledInput
              value={withdrawAmount}
              onChange={(e) => {
                setWithdrawAmount(e.target.value);
              }}
              className="w-[400px]"
            />
          </div>
        ) : (
          ""
        )}
        <div className="mt-4">
          {pending ? (
            <Oval visible={true} height="40" width="40" color="#4fa94d" />
          ) : (
            <Button onClick={() => onWithdraw()} className={"w-full"}>
              Withdraw
            </Button>
          )}
        </div>
        <div
          className="absolute right-2.5 top-2.5 w-8 h-8 z-10 font-black text-xs bg-[#B48FC5] text-[#2E2E2E] rounded box-shadow cursor-pointer flex justify-center items-center"
          onClick={() => setOpen(false)}
        >
          X
        </div>
      </Dialog.Panel>
    </Modal>
  );
}
