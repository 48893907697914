import { Dialog } from "@headlessui/react";
import { Modal } from "./Modal";
import { StyledInput } from "../StyledInput";
import { Button } from "../Button";
import { useState } from "react";

export default function WalletCreateModal({
  open,
  setOpen,
  onCreate,
}: {
  open: boolean;
  setOpen: any;
  onCreate: any;
}) {
  const [createAmount, setCreateAmount] = useState("");
  return (
    <Modal open={open} setOpen={setOpen}>
      <Dialog.Panel className="w-fit transform overflow-hidden rounded-2xl p-[23px_12px] pt-3 font-roboto text-left align-middle shadow-xl transition-all relative bg-white">
        <div className="font-bold text-2xl mb-5">Create Wallet</div>

        <div className="mt-4">
          <StyledInput
            value={createAmount}
            onChange={(e) => setCreateAmount(e.target.value)}
            className="w-52"
          />
          <Button
            onClick={() => {
              onCreate(createAmount);
              setOpen(false);
            }}
            className={"mt-4 w-full"}
          >
            Create
          </Button>
        </div>

        <div
          className="absolute right-2.5 top-2.5 w-8 h-8 z-10 font-black text-xs bg-[#B48FC5] text-[#2E2E2E] rounded box-shadow cursor-pointer flex justify-center items-center"
          onClick={() => setOpen(false)}
        >
          X
        </div>
      </Dialog.Panel>
    </Modal>
  );
}
