import { ethers } from "ethers";
import ERC20ABI from "../../config/abis/ERC20ABI.json";

import { tronWeb } from "../../tronweb";
import { multicall } from "../../utils/contracts";

export async function getTokenInfo(address) {
  try {
    let target = ethers.getAddress(
      `0x${tronWeb.address.toHex(address).substring(2)}`
    );
    let calls = [
      { name: "name", address: target },
      { name: "symbol", address: target },
      { name: "decimals", address: target },
    ];
    const result = await multicall(ERC20ABI, calls);
    return {
      name: result[0][0],
      symbol: result[1][0],
      decimals: Number(result[2][0]),
    };
  } catch (e) {
    console.log(e);
    return {};
  }
}
