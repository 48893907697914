import { ethers } from "ethers";
import { tronWeb } from "../tronweb";
import MulticallABI from "../config/abis/MulticallABI.json";
import { MULTICALL_ADDR } from "../config/constants";


export const multicall = async (abi, calls) => {
  const itf = new ethers.Interface(abi);
  const multicallContract = await tronWeb.contract(
    MulticallABI,
    MULTICALL_ADDR
  );
  const calldata = calls.map((call) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params),
  ]);

  const { returnData } = await multicallContract.aggregate(calldata).call();
  const res = returnData.map((call, i) =>
    itf.decodeFunctionResult(calls[i].name, call)
  );

  return res;
};
