import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTokenAddress, fetchTokenInfo } from ".";

export const useTokenInfoAsync = () => {
  const dispatch: any = useDispatch();
  const tokenInfo = useTokenInfo();

  useEffect(() => {
    dispatch(fetchTokenAddress());
  }, [dispatch]);

  useEffect(() => {
    if (!tokenInfo.address) return;
    dispatch(fetchTokenInfo(tokenInfo.address));
  }, [tokenInfo.address, dispatch]);
};

export const useTokenInfo = () =>
  useSelector((state: any) => state.token.token);
