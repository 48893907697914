/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useRefresh from "./useRefresh";
import { ethers } from "ethers";
import { tronWeb } from "../../tronweb";
import MulticallABI from "../../config/abis/MulticallABI.json";
import { MULTICALL_ADDR } from "../../config/constants";
import axios from "axios";

const defaultVal: any = {
  parentPK: "",
  updatePK: () => {},
  checkedAddresses: [],
  setCheckedAddresses: () => {},
  parentInfo: "",
  tronPrice: { price: 0, ethPrice: 0 },
  getBalance: () => {},
};

export const ProjectInfoContext = React.createContext(defaultVal);

export default function useProjectInfo() {
  return React.useContext(ProjectInfoContext);
}

export function ProjectInfoProvider({ children }: { children: any }) {
  const [parentPK, setParentPK] = useState("");
  const [checkedAddresses, setCheckedAddresses] = useState([]);
  const [tronPrice, setTronPrice] = useState({ price: 0, ethPrice: 0 });

  const [parentInfo, setParentInfo] = useState({
    ethBalance: "0",
    address: "",
  });

  const { slowRefresh } = useRefresh();

  function updatePK() {
    setParentPK(localStorage.getItem("tron-insidor-parent-pk"));
  }

  useEffect(() => {
    // const temp = [
    //   "TMn1qrmYUMSTXo9babrJLzepKZoPC7M6Sy",
    //   "TMDcjYpnfMnE8iXuVrnZXW7GyW99fQqrBa",
    //   "TBGpX7soivp4nYkxqPjsxEFsWKZT6icDEh",
    //   "TEQi9WGrf3jPxN8eA4PGFu3cXHoLkrXRAA",
    //   "TXPcwKSLbEwEjuAZcdVcqwrp8QRKq27ehB",
    //   "TJQSYokWjrHzHbi4UZjNXE6gNKemtv9C3p",
    //   "TSgvDgHbKq1PjXKtj4GMS7pahW5yxDQiHk",
    //   "TGRJXBg3h7PkiF3E6ccHg1LLnVUyK7Sz72",
    //   "TJ2FAge33QYMDTPupSQZxUSnWcdjJwPLTo",
    //   "TBc8SXqnDCnhZW8cYtwWEsan27nXpW6X9g",
    //   "TNisTrx9d86vnygufLNU1xonuYXWnrzvvx",
    //   "TS3dNyG2DGTQKanz3kDXFcFWPsLFh6U99V",
    //   "TEcxfezaPsUFchUC1ksLmKUzMcPopRCEdR",
    //   "TMyGHiQ9QyhCJ2u993Bq6zeTy1ER2gHhgA",
    //   "TS2WqHpDDdwVQa1onhwT12DUbjiFcnk6SM",
    //   "TLtFRH4sXRP9cqMXjjvtqKpNY1cXwzd7gQ",
    //   "TKZryw2FnkXdJLF4Ta8EMfgXU1crDNVaa2",
    //   "TUbpfHRXqg5FTyBXLMZx99kRaqmf8oBnEk",
    //   "TZHcnb9452sSMr1mpuaPM7M2ZyT3HBEyrm",
    //   "TRf13Rshcr9Lg4kr8ALrEMC4DjcN5BbRja",
    //   "TXaKPQoGW5WmnRy4aJ1LgasF7urAgcEFQN",
    //   "TNwmhrVQc11tqBGACLQRjHLr4eAvJWn6W1",
    //   "TDdxvgizBqGfWuEKxdEa7idGE3G1UczuBX",
    //   "TLexHEVb23Xq6VSm5rJ6oDKcihBkkKXh4X",
    //   "TCKjhZzX7xygXVsavjGBZzZPMJ3BmLX7US",
    //   "TCnPqaUvUoVkL3q2DapxNyYQ4FnBxmCaGZ",
    //   "TFvDYn5CQM35f82QPHhzFdXkfE5bTwdjay",
    //   "TPCXpAndDfzPjnTEK2uHecqvKa8o6cy9bU",
    //   "TGGzDxpusYM7z7KAi5T4kqrdKmyQ8aEynb",
    //   "TY9zv7Fzws3fUvxkWuu4UVD5Wj7e4hp5tD",
    // ];
    // let t = [];
    // for (let i = 0; i < temp.length; i++)
    //   t.push(
    //     ethers.getAddress(`0x${tronWeb.address.toHex(temp[i]).substring(2)}`)
    //   );
    // console.log(t);
    updatePK();
  }, []);

  async function getBalance() {
    try {
      const wallet = new ethers.Wallet(parentPK);
      const multicallContract = await tronWeb.contract(
        MulticallABI,
        MULTICALL_ADDR
      );
      const balance = await multicallContract
        .getEthBalance(wallet.address)
        .call();
      setParentInfo({
        address: tronWeb.address.fromHex(new ethers.Wallet(parentPK).address),
        ethBalance: BigInt(balance[0]).toString(),
      });
    } catch (e) {}
  }

  async function getTronPrice() {
    try {
      const to = Math.floor(Date.now() / 1000);
      const result = await Promise.all([
        axios.get(
          `https://api.dex.guru/v1/tradingview/history?symbol=0x50327c6c5a14dcade707abad2e27eb517df87ab5-eth_USD&resolution=10&from=${
            to - 3600
          }&to=${to}`
        ),
        axios.get(
          `https://api.dex.guru/v1/tradingview/history?symbol=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2-eth_USD&resolution=10&from=${
            to - 3600
          }&to=${to}`
        ),
      ]);
      const tronPrice = result[0].data.c[result[0].data.c.length - 1];
      const ethPrice = result[1].data.c[result[1].data.c.length - 1];
      setTronPrice({ price: tronPrice, ethPrice });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getBalance();
  }, [slowRefresh, parentPK]);

  useEffect(() => {
    getTronPrice();
  }, [slowRefresh]);

  return (
    <ProjectInfoContext.Provider
      value={{
        parentPK,
        updatePK,
        checkedAddresses,
        setCheckedAddresses,
        parentInfo,
        tronPrice,
        getBalance,
      }}
      children={children}
    />
  );
}
