import { useState } from "react";
import { Button } from "../../components/Button";
import DepositModal from "../../components/Modals/DepositModal";
import WithdrawModal from "../../components/Modals/WithdrawModal";
import TransferModal from "../../components/Modals/TransferModal";
import WalletCreateModal from "../../components/Modals/WalletCreateModal";
import useProjectInfo from "../hooks/useProjectinfo";
import { useAccountsInfo } from "../../state/account/hooks";
import { ethers } from "ethers";
import { tronWeb } from "../../tronweb";

export function ActionsPanel() {
  const [depositOpen, setDepositOpen] = useState(false);

  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [withdrawWallets, setWithdrawWallets] = useState([]);
  const [withdrawType, setWithdrawType] = useState("single");

  const [transferOpen, setTransferOpen] = useState(false);
  const [transferWallets, setTransferWallets] = useState([]);
  const [transferType, setTransferType] = useState("single");

  const { checkedAddresses } = useProjectInfo();
  const accounts = useAccountsInfo();

  function onExportSub() {
    const element = document.createElement("a");
    const file = new Blob(
      [
        JSON.stringify(
          accounts.map((account) =>
            ethers.getAddress(
              `0x${tronWeb.address.toHex(account.address).substring(2)}`
            )
          )
        ),
      ],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = "SubAddr.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  }

  return (
    <>
      <DepositModal
        open={depositOpen}
        setOpen={setDepositOpen}
        addresses={checkedAddresses}
      />
      <WithdrawModal
        open={withdrawOpen}
        setOpen={setWithdrawOpen}
        accounts={withdrawWallets}
        type={withdrawType}
      />
      <TransferModal
        open={transferOpen}
        setOpen={setTransferOpen}
        accounts={transferWallets}
        type={transferType}
      />

      <div className="mt-5 shadow-lg p-5 bg-white rounded-lg h-[300px]">
        <div className="text-2xl font-bold">Actions Panel</div>
        <div className="flex items-center flex-wrap">
          <div className="mr-4 mt-5">
            <Button onClick={() => setDepositOpen(true)}>Deposit</Button>
          </div>
          <div className="mr-4 mt-5">
            <Button
              onClick={() => {
                setWithdrawOpen(true);
                setWithdrawType("all");
                setWithdrawWallets(
                  accounts.filter((account) =>
                    checkedAddresses.includes(account.address)
                  )
                );
              }}
            >
              Withdraw All
            </Button>
          </div>
          <div className="mr-4 mt-5">
            <Button
              onClick={() => {
                setTransferOpen(true);
                setTransferType("all");
                setTransferWallets(
                  accounts.filter((account) =>
                    checkedAddresses.includes(account.address)
                  )
                );
              }}
            >
              Transfer All
            </Button>
          </div>
          <div className="mt-5">
            <Button
              onClick={() => {
                onExportSub();
              }}
            >
              Export Sub
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
