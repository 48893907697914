export function StyledCheckBox({ value, setValue, className }: any) {
  const baseClassName =
    "rounded w-full h-full cursor-pointer";
  return (
    <div className="p-[1px] w-5 h-5 border border-black rounded">
      {value ? (
        <div
          className={`${baseClassName} ${className} bg-[#003cff]`}
          onClick={setValue}
        />
      ) : (
        <div className={`${baseClassName} ${className}`} onClick={setValue} />
      )}
    </div>
  );
}
