import { useParams } from "react-router-dom";
import { WallletPanel } from "../WalletPanel";
import { useEffect } from "react";
import { tronWeb } from "../../tronweb";
import { ConfigurationPanel } from "../ConfigurationPanel";
import { PositionPanel } from "../PositionPanel";
import { ActionsPanel } from "../ActionsPanel";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { whitelists } from "../../config/constants";

export function Home() {
  const { address } = useParams();
  const { address: walletAddress } = useWallet();

  useEffect(() => {
    try {
      tronWeb.address.toHex(address);
      localStorage.setItem("tron-insidor-token-address", address);
    } catch (e) {
      console.log(e);
    }
  }, [address]);

  return walletAddress && whitelists.includes(walletAddress.toLowerCase()) ? (
    <div className="flex">
      <div className="flex-1 mr-5">
        <div className="flex-1">
          <ConfigurationPanel />
        </div>
        <div className="flex-1">
          <ActionsPanel />
        </div>
      </div>
      <div className="flex-1">
        <div className="flex-1 ">
          <WallletPanel />
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
