export function StyledInput({
  value,
  onChange,
  className,
  placeholder,
  onKeyDown,
  type = "text",
}: any) {
  const baseClassName =
    "border border-[#bdbdbd] !p-[10px_8px] rounded text-sm flex";
  return (
    <input
      type={type}
      value={value}
      className={`${baseClassName} ${className}`}
      onChange={onChange}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
    />
  );
}
