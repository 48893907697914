import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccountETHBalances,
  fetchAccountPKs,
  fetchAccountTokenBalances,
} from ".";
import useRefresh from "../../pages/hooks/useRefresh";
import { useTokenInfo } from "../token/hooks";

export const useAccountsInfoAsync = () => {
  const dispatch: any = useDispatch();

  const { slowRefresh } = useRefresh();
  const accounts = useAccountsInfo();
  const token = useTokenInfo();

  const addresses = accounts.map((account) => account.address);
  const strinigfiedAddresses = JSON.stringify(addresses);

  useEffect(() => {
    dispatch(fetchAccountPKs());
  }, [dispatch]);

  useEffect(() => {
    if (!addresses.length) return;
    dispatch(fetchAccountETHBalances(addresses));
  }, [slowRefresh, strinigfiedAddresses]);

  useEffect(() => {
    if (!addresses.length || !token.address) return;
    dispatch(fetchAccountTokenBalances(token.address, addresses));
  }, [strinigfiedAddresses, token.address]);
};

export const useAccountsInfo = () =>
  useSelector((state: any) => state.account.accounts);
