import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

import token from "./token";
import account from "./account";

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    token: token,
    account,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== "production"
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware(),
});

export default store;
