import { useEffect, useMemo, useState } from "react";
import type { WalletError } from "@tronweb3/tronwallet-abstract-adapter";
import {
  WalletDisconnectedError,
  WalletNotFoundError,
} from "@tronweb3/tronwallet-abstract-adapter";
import {
  useWallet,
  WalletProvider,
} from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  WalletActionButton,
  WalletModalProvider,
} from "@tronweb3/tronwallet-adapter-react-ui";
import toast from "react-hot-toast";
import {
  BitKeepAdapter,
  OkxWalletAdapter,
  TokenPocketAdapter,
  TronLinkAdapter,
} from "@tronweb3/tronwallet-adapters";
import { WalletConnectAdapter } from "@tronweb3/tronwallet-adapter-walletconnect";
import { tronWeb } from "./tronweb";
import { LedgerAdapter } from "@tronweb3/tronwallet-adapter-ledger";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { StyledInput } from "./components/StyledInput";
import { useTokenInfo, useTokenInfoAsync } from "./state/token/hooks";
import { Home } from "./pages/Home";
import { useAccountsInfo, useAccountsInfoAsync } from "./state/account/hooks";
import { whitelists } from "./config/constants";

/**
 * wrap your app content with WalletProvider and WalletModalProvider
 * WalletProvider provide some useful properties and methods
 * WalletModalProvider provide a Modal in which you can select wallet you want use.
 *
 * Also you can provide a onError callback to process any error such as ConnectionError
 */
export function App() {
  function onError(e: WalletError) {
    if (e instanceof WalletNotFoundError) {
      toast.error(e.message);
    } else if (e instanceof WalletDisconnectedError) {
      toast.error(e.message);
    } else toast.error(e.message);
  }
  const adapters = useMemo(function () {
    const tronLinkAdapter = new TronLinkAdapter();
    const walletConnectAdapter = new WalletConnectAdapter({
      network: "Nile",
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        // example WC app project ID
        projectId: "5fc507d8fc7ae913fff0b8071c7df231",
        metadata: {
          name: "Test DApp",
          description: "JustLend WalletConnect",
          url: "https://your-dapp-url.org/",
          icons: ["https://your-dapp-url.org/mainLogo.svg"],
        },
      },
      web3ModalConfig: {
        themeMode: "dark",
        themeVariables: {
          "--w3m-z-index": "1000",
        },
      },
    });
    const ledger = new LedgerAdapter({
      accountNumber: 2,
    });
    const bitKeepAdapter = new BitKeepAdapter();
    const tokenPocketAdapter = new TokenPocketAdapter();
    const okxwalletAdapter = new OkxWalletAdapter();
    return [
      tronLinkAdapter,
      bitKeepAdapter,
      tokenPocketAdapter,
      okxwalletAdapter,
      walletConnectAdapter,
      ledger,
    ];
  }, []);

  useTokenInfoAsync();
  useAccountsInfoAsync();

  const tokenInfo = useTokenInfo();

  return (
    <WalletProvider
      onError={onError}
      autoConnect={true}
      disableAutoConnectOnLoad={true}
      adapters={adapters}
    >
      <WalletModalProvider>
        <BrowserRouter>
          <UIComponent></UIComponent>
          {/* <Profile></Profile> */}
          {/* <SignDemo></SignDemo> */}
          <Routes>
            {tokenInfo.address ? (
              <Route
                path="/"
                element={<Navigate to={`/contract/${tokenInfo.address}`} />}
              />
            ) : (
              ""
            )}
            <Route path="/contract/:address" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </WalletModalProvider>
    </WalletProvider>
  );
}

function UIComponent() {
  const [tokenAddress, setTokenAddress] = useState("");

  function onKeyDown(event) {
    if (event.keyCode === 13) {
      console.log("Enter Pressed");
      try {
        tronWeb.address.toHex(tokenAddress);
        window.open(`/contract/${tokenAddress}`, "_self");
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <div className="flex justify-between items-center">
      <StyledInput
        placeholder="Search token..."
        className="w-[400px]"
        value={tokenAddress}
        onChange={(e) => setTokenAddress(e.target.value)}
        onKeyDown={(e) => onKeyDown(e)}
      />
      <WalletActionButton />
    </div>
  );
}
