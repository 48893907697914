import { useState } from "react";
import { StyledInput } from "../../components/StyledInput";
import { Button } from "../../components/Button";
import useProjectInfo from "../hooks/useProjectinfo";
import { useAccountsInfo } from "../../state/account/hooks";
import TronWeb from "tronweb";
import {
  API_URL,
  BOT_ROUTER_ADDR,
  ROUTER_ADDR,
  SUNPUMP_ROUTER_ADDR,
  WETH_ADDR,
} from "../../config/constants";
import RouterABI from "../../config/abis/RouterABI.json";
import ERC20ABI from "../../config/abis/ERC20ABI.json";
import { useTokenInfo } from "../../state/token/hooks";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import {
  fetchAccountETHBalances,
  fetchAccountTokenBalances,
} from "../../state/account";
import { StyledCheckBox } from "../../components/StyledCheckBox";

const routers = [ROUTER_ADDR, SUNPUMP_ROUTER_ADDR, BOT_ROUTER_ADDR];

export function ConfigurationPanel() {
  const [amount, setAmount] = useState("1000");
  const [minAmount, setMinAmount] = useState("700");
  const [maxAmount, setMaxAmount] = useState("1500");
  const [sellPercent, setSellPercent] = useState("100");
  const [buyPending, setBuyPending] = useState(false);
  const [sellPending, setSellPending] = useState(false);
  const [isRandom, setIsRandom] = useState(false);
  const [isSunSwap, setIsSunSwap] = useState(0);

  const { checkedAddresses } = useProjectInfo();
  const accounts = useAccountsInfo();
  const token = useTokenInfo();
  console.log(accounts);

  const dispatch: any = useDispatch();

  async function onBuy() {
    if (!checkedAddresses.length) {
      toast.success("No account selected");
      return;
    }
    setBuyPending(true);
    const checkedAccounts = accounts.filter((account) =>
      checkedAddresses.includes(account.address)
    );

    try {
      const FINAL_ROUTER = routers[isSunSwap];
      await Promise.all(
        checkedAccounts.map(async (account) => {
          const tronWeb = new TronWeb({
            fullHost: API_URL,
            solidityNode: API_URL,
            eventServer: API_URL,
            privateKey: account.privateKey,
          });

          const rAmount = Number(
            Number(minAmount) +
              (Number(maxAmount) - Number(minAmount)) * Math.random()
          ).toFixed(3);

          let finalAmount = isRandom ? rAmount : amount;
          if (
            Number(finalAmount) >
            Number(ethers.formatUnits(account.ethBalance, 6))
          ) {
            finalAmount = (
              Number(ethers.formatUnits(account.ethBalance, 6)) * 0.5
            ).toFixed(3);
          }

          console.log(finalAmount);
          const RouterContract = await tronWeb.contract(
            RouterABI,
            FINAL_ROUTER
          );

          await RouterContract.swapExactETHForTokens(
            0,
            [WETH_ADDR, token.address],
            account.address,
            Math.floor(Date.now() / 1000 + 3000)
          ).send({
            callValue: ethers.parseUnits(finalAmount, 6),
          });
          const allowance = [
            account.allowance,
            account.sunpumpAllowance,
            account.botAllowance,
          ];
          if (
            BigInt(allowance[isSunSwap]) <
            BigInt(ethers.parseEther("10000000000"))
          ) {
            const tokenContract = await tronWeb.contract(
              ERC20ABI,
              token.address
            );

            await tokenContract.approve(FINAL_ROUTER, ethers.MaxUint256).send();
          }
        })
      );
      toast.success("Buy successfully");
    } catch (e) {
      console.log(e);
      toast.error("Error");
    }
    dispatch(fetchAccountETHBalances(checkedAddresses));
    dispatch(fetchAccountTokenBalances(token.address, checkedAddresses));
    setBuyPending(false);
  }

  async function onSell() {
    setSellPending(true);
    const checkedAccounts = accounts.filter((account) =>
      checkedAddresses.includes(account.address)
    );

    try {
      const FINAL_ROUTER = routers[isSunSwap];
      await Promise.all(
        checkedAccounts.map(async (account) => {
          if (
            BigInt(account.tokenBalance) === BigInt(ethers.parseUnits("0", 6))
          )
            return;
          const tronWeb = new TronWeb({
            fullHost: API_URL,
            solidityNode: API_URL,
            eventServer: API_URL,
            privateKey: account.privateKey,
          });
          const allowance = [
            account.allowance,
            account.sunpumpAllowance,
            account.botAllowance,
          ];
          console.log(allowance[isSunSwap], isSunSwap);
          if (
            BigInt(allowance[isSunSwap]) <
            BigInt(ethers.parseEther("10000000000"))
          ) {
            const tokenContract = await tronWeb.contract(
              ERC20ABI,
              token.address
            );

            await tokenContract.approve(FINAL_ROUTER, ethers.MaxUint256).send();
          }

          const RouterContract = await tronWeb.contract(
            RouterABI,
            FINAL_ROUTER
          );
          await RouterContract.swapExactTokensForETHSupportingFeeOnTransferTokens(
            (BigInt(account.tokenBalance) * BigInt(sellPercent)) / BigInt(100),
            0,
            [token.address, WETH_ADDR],
            account.address,
            Math.floor(Date.now() / 1000 + 3000)
          ).send();
        })
      );
      toast.success("Sell successfully");
    } catch (e) {
      console.log(e);
      toast.error("Error");
    }
    dispatch(fetchAccountETHBalances(checkedAddresses));
    dispatch(fetchAccountTokenBalances(token.address, checkedAddresses));
    setSellPending(false);
  }
  return (
    <div className="mt-5 shadow-lg p-5 bg-white rounded-lg">
      <div className="text-2xl font-bold">Configuration Panel</div>
      <div className="flex items-center mt-3">
        <StyledCheckBox
          value={isSunSwap === 0}
          setValue={() => setIsSunSwap(0)}
        />
        <div className="ml-2 mr-4">SunSwap</div>
        <StyledCheckBox
          value={isSunSwap === 1}
          setValue={() => setIsSunSwap(1)}
        />
        <div className="ml-2 mr-4">SunPump</div>
        <StyledCheckBox
          value={isSunSwap === 2}
          setValue={() => setIsSunSwap(2)}
        />
        <div className="ml-2">Bot</div>
      </div>
      <div className="flex items-center mt-3">
        <div className="">
          <div>Amount</div>
          <StyledInput
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-24"
          />
        </div>
        <div className="mt-6 ml-4 flex items-center">
          <StyledCheckBox
            value={isRandom}
            setValue={() => setIsRandom(!isRandom)}
          />
          <div className="ml-2">Random</div>
        </div>
      </div>
      <div className="flex items-center mt-2">
        <div>
          <div>Min Amount</div>
          <StyledInput
            value={minAmount}
            onChange={(e) => setMinAmount(e.target.value)}
            className="w-24"
          />
        </div>
        <div className="ml-4">
          <div>Max Amount</div>
          <StyledInput
            value={maxAmount}
            onChange={(e) => setMaxAmount(e.target.value)}
            className="w-24"
          />
        </div>
        <div className="ml-4">
          <div>Sell Percent</div>
          <StyledInput
            value={sellPercent}
            onChange={(e) => setSellPercent(e.target.value)}
            className="w-24"
          />
        </div>
      </div>
      <div className="flex mt-5">
        {buyPending ? (
          <Oval visible={true} height="40" width="40" color="#4fa94d" />
        ) : (
          <Button className="mr-4" onClick={() => onBuy()}>
            Buy
          </Button>
        )}
        {sellPending ? (
          <Oval visible={true} height="40" width="40" color="#4fa94d" />
        ) : (
          <Button onClick={() => onSell()}>Sell</Button>
        )}
      </div>
    </div>
  );
}
