/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from "@reduxjs/toolkit";
import { getTokenInfo } from "./fetchTokenInfo";

const initialState = {
  token: {
    address: "",
  },
};

export const fetchTokenInfo = (address) => async (dispatch: any) => {
  const data = await getTokenInfo(address);
  dispatch(setTokenInfo(data));
};

export const fetchTokenAddress = () => async (dispatch: any) => {
  try {
    const address = localStorage.getItem("tron-insidor-token-address");
    dispatch(setTokenAddress(address));
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setTokenInfo: (state: any, action) => {
      state.token = { ...state.token, ...action.payload };
    },
    setTokenAddress: (state: any, action) => {
      state.token.address = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setTokenInfo, setTokenAddress } = analyticsSlice.actions;
export default analyticsSlice.reducer;
