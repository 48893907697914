import { ethers } from "ethers";
import ERC20ABI from "../../config/abis/ERC20ABI.json";
import MulticallABI from "../../config/abis/MulticallABI.json";

import { tronWeb } from "../../tronweb";
import { multicall } from "../../utils/contracts";
import {
  BOT_ROUTER_ADDR,
  MULTICALL_ADDR,
  ROUTER_ADDR,
  SUNPUMP_ROUTER_ADDR,
} from "../../config/constants";

export async function getAccountETHBalances(addresses) {
  try {
    let calls = [];
    for (let i = 0; i < addresses.length; i++) {
      let target = ethers.getAddress(
        `0x${tronWeb.address.toHex(addresses[i]).substring(2)}`
      );
      const multi_eth_addr = ethers.getAddress(
        `0x${tronWeb.address.toHex(MULTICALL_ADDR).substring(2)}`
      );
      calls.push({
        name: "getEthBalance",
        address: multi_eth_addr,
        params: [target],
      });
    }

    const result = await multicall(MulticallABI, calls);
    return result.map((balance, i) => ({
      address: addresses[i],
      balance: balance[0].toString(),
    }));
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAccountTokenBalances(token, addresses) {
  try {
    let calls = [];
    const token_eth_addr = ethers.getAddress(
      `0x${tronWeb.address.toHex(token).substring(2)}`
    );
    for (let i = 0; i < addresses.length; i++) {
      const target = ethers.getAddress(
        `0x${tronWeb.address.toHex(addresses[i]).substring(2)}`
      );
      calls.push({
        name: "balanceOf",
        address: token_eth_addr,
        params: [target],
      });

      calls.push({
        name: "allowance",
        address: token_eth_addr,
        params: [
          target,
          `0x${tronWeb.address.toHex(ROUTER_ADDR).substring(2)}`,
        ],
      });

      calls.push({
        name: "allowance",
        address: token_eth_addr,
        params: [
          target,
          `0x${tronWeb.address.toHex(BOT_ROUTER_ADDR).substring(2)}`,
        ],
      });
    }

    const result = await multicall(ERC20ABI, calls);

    let t = [];
    for (let i = 0; i < addresses.length; i++) {
      t.push({
        address: addresses[i],
        balance: result[i * 3][0].toString(),
        allowance: result[i * 3 + 1][0].toString(),
        botAllowance: result[i * 3 + 2][0].toString(),
        sunpumpAllowance: "0",
      });
    }
    return t;
  } catch (e) {
    console.log(e);
    return [];
  }
}
